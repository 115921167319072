<template>
    <v-list-item two-line >
      <v-list-item-icon>
        <v-icon large class="mt-4">
          mdi-note
        </v-icon>
      </v-list-item-icon>
      <v-list-item-content>
        <v-list-item-title>
          {{file.name}}
        </v-list-item-title>
        <v-list-item-subtitle>
          {{ formatByte(file.size) }}
         </v-list-item-subtitle>
      </v-list-item-content>

      <v-progress-linear
        color="white"
        indeterminate
        rounded
        height="6"
        style="width:100px"
        v-if="loading"
      ></v-progress-linear>
      <v-list-item-icon class="" v-if="uploaded && !loading">
          <v-icon color="white" class="mt-4"> mdi-check </v-icon>
      </v-list-item-icon>

      <v-list-item-icon class="" v-if="actionClass === 'error' && !loading">
          <v-icon color="white" class="mt-4"> mdi-check </v-icon>
      </v-list-item-icon>

      <v-list-item-action>
        <v-list-item-icon class="" v-if="uploaded">
          <v-btn icon tile outlined :color="modalColor" class="rounded-lg" @click="$emit('delete')">
            <i class="far fa-trash-alt white--text"></i>
          </v-btn>
        </v-list-item-icon>
      </v-list-item-action>
     </v-list-item>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import colorMxn from "@/mixins/colorMxn";
import validationMxn from "@/mixins/validation_mixin";
import generalMxn from '@/mixins/general_mixin';

export default {
  name: 'fileUploadComponent',
  props: ['file', 'upload_type'],
  mixins: [colorMxn, validationMxn, generalMxn],
  data() {
    return {
      uploaded: true,
      loading: false,
      error: '',
      actionClass: 'success',
			message: '',
    }
  },
  computed: {
		...mapGetters(['getSession']),
	},
  mounted() {
    if (this.file) {
      this.submitFiles();
    }
  },
  methods: {
    ...mapActions(['performPutActions', 'refreshAuthToken']),
    ...mapMutations(['setUploadedFile']),
    async submitFiles() {
      this.loading = true;
      const formData = new FormData()

      formData.append('file', this.file);

      const s3Object = {
        upload_type: this.upload_type,
        order_id: this.$route.params.id,
        user_id: 1,
      }
      
      const fullPayload = {
        params: formData,
        endpoint: `/file/upload/s3/?details=${JSON.stringify(s3Object)}`,
      }

      const response = await this.uploadImage(fullPayload);
      this.loading = false;
      this.message = response.message;
			this.actionClass = response.status ? "success" : "error";
      this.uploaded = response.status;
      this.setUploadedFile(response.data);

    },

  }
}
</script>